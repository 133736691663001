<template>
    <div class="mw-100 p-2">
        <div class="section">
            <div class="card">
                <div class="card-body" v-if="step === 'form'">
                    <ValidationObserver ref="observer" slim>
                        <div class="row">
                            <div class="col-12">
                                <p class="f-16 font-600 text-black">User Information</p>
                                <div class="table-responsive">
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td class="text-left align-top pl-0 pb-0 pt-0 border-0">
                                                    {{ user.firstName || '-' }} {{  user.lastName }}
                                                </td>
                                                <td class="text-left align-top pl-0 pb-0 pt-0 border-0" v-if="user.mobileNumber">
                                                    {{ user.mobileNumber }}
                                                </td>
                                                <td class="text-left align-top pl-0 pb-0 pt-0 border-0" v-if="user.email">
                                                    {{ user.email }}
                                                </td>
                                                <td class="text-left align-top pl-0 pb-0 pt-0 border-0" v-if="user.omnicarePatientId">
                                                    {{ user.omnicarePatientId }}
                                                </td>
                                                <td class="text-left align-top pl-0 pb-0 pt-0 border-0">
                                                    <router-link :to="generateEditUrl" class="edit-user-btn bg-warning">
                                                        <VTooltip>
                                                            <i class="fa fa-pencil"></i>
                                                            <template #popper>
                                                                Edit user information
                                                            </template>
                                                        </VTooltip>
                                                    </router-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 mt-4">
                                <p class="f-16 font-600 text-black">Clinic Information</p>
                                <div
                                    class="d-flex align-items-center gap-1"
                                    style="margin-top: 12px;"
                                >
                                    <div style="flex: 0 0 160px;">
                                        Clinic Name
                                    </div>
                                    <div class="d-flex align-items-center gap-1" style="flex: 1;">
                                        <ValidationProvider
                                            name="form.clinic"
                                            v-slot="{ errors }"
                                            rules="required"
                                            slim
                                        >
                                            <div
                                                :class="{ 'error-input-multiselect': errors[0] }"
                                                style="flex: 1 1 50%"
                                            >
                                                <multiselect
                                                    v-model="form.clinic"
                                                    placeholder="Select Clinic Name"
                                                    :options="options.clinic"
                                                    label="name"
                                                    track-by="id"
                                                    select-label=""
                                                    deselect-label=""
                                                    :multiple="false"
                                                    :allow-empty="false"
                                                    :loading="!options.clinic.length"
                                                    @input="getAvailableSchedule()"
                                                    :disabled="isLoading"
                                                >
                                                    <span slot="noResult">Oops! No data found.</span>
                                                </multiselect>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-12 mt-4" id="treatment-information">
                                <p class="f-16 font-600 text-black">Treatment Information</p>
                                <div
                                    class="d-flex align-items-center gap-1"
                                    v-for="(_, treatmentKey) in form.treatment"
                                    :key="treatmentKey"
                                    :style="`margin-top: ${treatmentKey === 0 ? 0 : 12}px; min-width: 520px;`"
                                >
                                    <div class="d-flex align-items-center gap-3" style="flex: 1;">
                                        <ValidationProvider
                                            :name="`treatment.${treatmentKey}.group`"
                                            v-slot="{ errors }"
                                            rules="required"
                                            slim
                                        >
                                            <div
                                                :class="{ 'error-input-multiselect': errors[0] }"
                                                style="flex: 1 1 50%"
                                            >
                                                <multiselect
                                                    v-model="form.treatment[treatmentKey].group"
                                                    placeholder="Select Treatment Group"
                                                    :options="options.treatmentGroup"
                                                    label="name"
                                                    track-by="id"
                                                    select-label=""
                                                    deselect-label=""
                                                    :multiple="false"
                                                    :allow-empty="false"
                                                    :loading="!options.treatmentGroup.length"
                                                    :disabled="isLoading"
                                                    @input="(event) => handleSelectTreatmentGroup(event, treatmentKey)"
                                                >
                                                    <span slot="noResult">Oops! No data found.</span>
                                                    <span slot="noOptions">Treatment group is empty.</span>
                                                </multiselect>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider
                                            :name="`treatment.${treatmentKey}.detail`"
                                            v-slot="{ errors }"
                                            rules="required"
                                            slim
                                        >
                                            <div
                                                :class="{ 'error-input-multiselect': errors[0] }"
                                                style="flex: 1 1 50%"
                                            >
                                                <multiselect
                                                    v-model="form.treatment[treatmentKey].detail"
                                                    placeholder="Select Treatment Detail"
                                                    :options="getTreatmentDetailOptions(form.treatment[treatmentKey].group)"
                                                    label="title"
                                                    track-by="id"
                                                    select-label=""
                                                    deselect-label=""
                                                    :multiple="false"
                                                    :allow-empty="false"
                                                    :loading="!form.treatment[treatmentKey].detail && isLoadingTreatmentDetail"
                                                    :disabled="!form.treatment[treatmentKey].group || isLoading"
                                                    @input="getAvailableSchedule()"
                                                >
                                                    <span slot="noResult">Oops! No data found.</span>
                                                    <span slot="noOptions">Treatment detail is empty.</span>
                                                </multiselect>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div
                                        class="d-flex align-items-center gap-2 justify-content-end f-18"
                                        style="flex: 0 0 50px;"
                                    >
                                        <div
                                            class="cursor-pointer"
                                            style="width: fit-content;"
                                            @click="removeTreatment(treatmentKey)"
                                            v-if="form.treatment.length > 1"
                                        >
                                            <i class="fa fa-trash"></i>
                                        </div>
                                        <div
                                            :class="{
                                                'cursor-pointer': treatmentKey === form.treatment.length - 1,
                                                'invisible': treatmentKey !== form.treatment.length - 1,
                                            }"
                                            @click="
                                                // only allow to add new treatment
                                                // when current row is completely selected
                                                (treatmentKey === form.treatment.length - 1
                                                && form.treatment[treatmentKey].group
                                                && form.treatment[treatmentKey].detail)
                                                && addNewTreatment()
                                            "
                                            :style="`
                                                width: fit-content;
                                                margin-top: 2px;
                                                ${(!form.treatment[treatmentKey].group
                                                || !form.treatment[treatmentKey].detail)
                                                && 'opacity: 0.55;'}
                                            `"
                                        >
                                            <i class="fa fa-plus"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <label class="mt-3">
                                    <input
                                        type="checkbox"
                                        v-model="form.withConsultation"
                                        :disabled="!user.hasTreatmentHistory || isLoading"
                                        @input="getAvailableSchedule()"
                                    >
                                    Doctor Consultation
                                </label>
                                <div class="d-flex align-items-center gap-1 mt-2" v-if="form.withConsultation">
                                    <div style="flex: 0 0 160px;">
                                        Doctor Name
                                    </div>
                                    <div class="d-flex align-items-center gap-1" style="flex: 1;">
                                        <ValidationProvider
                                            name="form.doctor"
                                            v-slot="{ errors }"
                                            :rules="{ required: form.withConsultation }"
                                            slim
                                        >
                                            <div
                                                :class="{ 'error-input-multiselect': errors[0] }"
                                                style="flex: 1 1 50%"
                                            >
                                                <multiselect
                                                    v-model="form.doctor"
                                                    placeholder="Select Doctor Name"
                                                    :options="options.doctor"
                                                    label="adminName"
                                                    track-by="id"
                                                    select-label=""
                                                    deselect-label=""
                                                    :multiple="false"
                                                    :allow-empty="false"
                                                    :disabled="isLoading"
                                                    @input="getAvailableSchedule()"
                                                >
                                                    <span slot="noResult">Oops! No data found.</span>
                                                </multiselect>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-4">
                                <p class="f-16 font-600 text-black">Booking Information</p>
                            </div>
                            <div class="col-12">
                                <div class="d-flex flex-wrap gap-3 align-items-start">
                                    <div style="flex: 0 0 auto; margin-right: 36px;" id="schedule-date">
                                        <date-picker
                                            inline
                                            v-model="form.date"
                                            type="date"
                                            value-type="YYYY-MM-DD"
                                            :disabled-date="(selectedValue) => generateDateOptions(selectedValue)"
                                            :clearable="false"
                                            @input="getAvailableSchedule()"
                                        />
                                    </div>
                                    <div
                                        class="position-relative"
                                        :style="
                                            `flex: 0 1 420px;
                                            opacity: ${isLoading ? 0.35 : 1};`
                                        "
                                    >
                                        <div class="schedule-loader" v-if="isLoading">
                                            <i class="fa fa-spinner fa-spin"></i>
                                        </div>
                                        <div
                                            v-for="(availableSchedule, availableScheduleKey) in groupAvailableScheduleByLabel"
                                            :key="availableScheduleKey"
                                            :style="
                                                `margin-top: ${availableScheduleKey === 0 ? 0 : 16}px;
                                                max-height: ${!openAccordion.includes(availableSchedule.label) ? '20px' : '1000px;'};
                                                overflow: hidden;`
                                            "
                                            class="transition-all-ease"
                                        >
                                            <div
                                                class="d-flex align-items-center justify-content-between cursor-pointer"
                                                @click="handleToggleAccordion(availableSchedule.label)"
                                            >
                                                <span>{{ availableSchedule.label }}</span>
                                                <i
                                                    class="fa fa-chevron-down transition-all-ease"
                                                    :style="`transform: rotate(${!openAccordion.includes(availableSchedule.label) ? 0 : 180}deg);`"
                                                >
                                                </i>
                                            </div>
                                            <div
                                                v-if="isFormCompleted && availableSchedule.list.length > 0"
                                                class="available-schedule-list"
                                                style="margin-top: 4px;"
                                            >
                                                <div
                                                    v-for="(schedule, scheduleKey) in availableSchedule.list"
                                                    :key="scheduleKey"
                                                    class="schedule-list"
                                                >
                                                    <VTooltip>
                                                        <button
                                                            type="button"
                                                            class="btn-schedule-list"
                                                            :class="{
                                                                'btn-schedule-list-disabled': !schedule.isAvailable,
                                                                'btn-schedule-list-offside': !schedule.isOperationalHour && schedule.isAvailable,
                                                                'btn-schedule-list-selected': (schedule.time === form.time),
                                                            }"
                                                            :disabled="!schedule.isAvailable"
                                                            @click="schedule.isAvailable && setScheduleTime(schedule.time)"
                                                        >
                                                            {{ schedule.time }}
                                                        </button>
                                                        <template #popper>
                                                            {{  generateScheduleTimeTooltip(schedule)  }}
                                                        </template>
                                                    </VTooltip>
                                                </div>
                                            </div>
                                            <div
                                                v-else
                                                class="available-schedule-none"
                                                style="margin-top: 4px;"
                                            >
                                                {{
                                                    (!isFormCompleted && !availableSchedule.list.length)
                                                        ? 'Please complete the form above.'
                                                        : 'No available schedule.'
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="d-flex justify-content-end">
                            <button class="btn text-danger" :disabled="isLoading" @click="openConfirmationExitPageModal()">
                                Cancel
                            </button>
                            <button type="submit" class="btn btn-primary" :disabled="isLoading" @click="onSave()">
                                <div v-if="isLoading">Loading <i class="fa fa-spinner fa-spin"></i></div>
                                <div v-else>Save</div>
                            </button>
                        </div>
                    </ValidationObserver>
                </div>
                <div class="card-body" v-else>
                    <div class="row">
                        <div class="col-12">
                            <p class="f-16 font-600 text-black">User Information</p>
                            <div class="table-responsive">
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <td class="text-left align-top pl-0 pb-0 pt-0 border-0">
                                                {{ user.firstName || '-' }} {{  user.lastName }}
                                            </td>
                                            <td class="text-left align-top pl-0 pb-0 pt-0 border-0" v-if="user.mobileNumber">
                                                {{ user.mobileNumber }}
                                            </td>
                                            <td class="text-left align-top pl-0 pb-0 pt-0 border-0" v-if="user.email">
                                                {{ user.email }}
                                            </td>
                                            <td class="text-left align-top pl-0 pb-0 pt-0 border-0" v-if="user.omnicarePatientId">
                                                {{ user.omnicarePatientId }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-12 mt-3">
                            <p class="f-16 font-600 text-black">Clinic Information</p>
                            <div>
                                <span class="font-600 text-black">Clinic Name: </span>{{ summaryData.clinicName  }}
                            </div>
                        </div>
                        <div class="col-12 mt-4">
                            <p class="f-16 font-600 text-black">Treatment Information</p>
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="font-600 text-black text-left pl-0 pt-0 border-0 bg-transparent">Treatment Group</th>
                                            <th class="font-600 text-black text-left pl-0 pt-0 border-0 bg-transparent">Treatment Detail</th>
                                            <th class="font-600 text-black text-left pl-0 pt-0 border-0 bg-transparent">Type</th>
                                            <th class="font-600 text-black text-left pl-0 pt-0 border-0 bg-transparent">Duration</th>
                                            <th class="font-600 text-black text-left pl-0 pt-0 border-0 bg-transparent">Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(treatment, key) in summaryData.treatmentInformation" :key="key">
                                            <td class="text-left align-top pl-0 pt-0 border-0">
                                                {{ treatment.group.name }}
                                            </td>
                                            <td class="text-left align-top pl-0 pt-0 border-0">
                                                {{ treatment.detail.title }}
                                            </td>
                                            <td class="text-left align-top pl-0 pt-0 border-0">
                                                {{ treatment.detail.type }}
                                            </td>
                                            <td class="text-left align-top pl-0 pt-0 border-0">
                                                {{ treatment.detail.durationInMinutes }} minutes
                                            </td>
                                            <td class="text-left align-top pl-0 pt-0 border-0">
                                                {{ treatment.detail.price | currency }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-12 mt-2">
                            <div>
                                <span class="font-600 text-black">Doctor: </span>{{ summaryData.doctorName  }}
                            </div>
                            <div class="mt-1">
                                <span class="font-600 text-black">Total Treatment: </span>{{ summaryData.treatmentInformation.length }}
                            </div>
                            <div class="mt-1">
                                <span class="font-600 text-black">Estimated Total Price: </span>{{ summaryData.estimatedTotalPrice | currency }}
                            </div>
                        </div>
                        <div class="col-12 mt-4">
                            <p class="f-16 font-600 text-black">Booking Information</p>
                            <div>
                                <span class="font-600 text-black">Treatment Date: </span>{{ summaryData.treatmentDate }}
                            </div>
                            <div class="mt-1">
                                <span class="font-600 text-black">Treatment Time: </span>{{ summaryData.treatmentTime }}
                            </div>
                        </div>
                        <div class="col-12 mt-4">
                            <span class="text-danger">Please double check information above and make sure you input information correctly: selected user, clinic, treatment, and booking.</span>
                        </div>
                    </div>
                    <hr>
                    <div class="d-flex justify-content-end">
                        <button class="btn text-danger" :disabled="isLoading" @click="toggleStep()">
                            Edit
                        </button>
                        <button class="btn btn-primary" :disabled="isLoading" @click="openConfirmationBookAppointmentModal()">
                            <div v-if="isLoading">Loading <i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Book Apointment</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal confirmation exit page -->
        <modal :name="CONFIRMATION_EXIT_PAGE_MODAL" :adaptive="true" height="auto" :width="500" style="z-index: 2000;">
            <div class="modal-container p-2">
                <p class="f-16 text-black font-600" style="padding-right: 32px;">Are you sure want to exit this page?</p>
                <hr>
                <div class="d-flex justify-content-end">
                    <button class="btn text-danger" @click="closeConfirmationExitPageModal()">
                        No
                    </button>
                    <button class="btn btn-primary" @click="closeConfirmationExitPageModal(true)">
                        Yes
                    </button>
                </div>
            </div>
        </modal>
        <!-- Modal confirmation book appointment -->
        <modal :name="CONFIRMATION_BOOK_APPOINTMENT_MODAL" :adaptive="true" height="auto" :width="500" style="z-index: 2000;">
            <div class="modal-container p-2">
                <p class="f-16 text-black font-600" style="padding-right: 32px;">Are you sure want to create this appointment?</p>
                <hr>
                <div class="d-flex justify-content-end">
                    <button class="btn text-danger" @click="closeConfirmationBookAppointmentModal()">
                        No
                    </button>
                    <button class="btn btn-primary" @click="closeConfirmationBookAppointmentModal(true)">
                        Yes
                    </button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    const CONFIRMATION_EXIT_PAGE_MODAL = 'confirmation_exit_page_modal';
    const CONFIRMATION_BOOK_APPOINTMENT_MODAL = 'confirmation_book_appointment_modal';

    export default {
        data() {
            return {
                step: 'form', // form, summary
                user: {},
                form: {
                    clinic: null,
                    treatment: [
                        { group: null, detail: null },
                    ],
                    doctor: { id: 0, adminName: 'Any Doctor' },
                    // withConsultation forced to be true if user.hasTreatmentHistory is false
                    withConsultation: false,
                    date: this.$moment().format('YYYY-MM-DD'),
                    time: null,
                },
                options: {
                    clinic: [],
                    treatmentGroup: [],
                    doctor: [{ id: 0, adminName: 'Any Doctor' }],
                    availableSchedule: [],
                },
                config: {},
                openAccordion: [],
                isLoading: false,
                isLoadingTreatmentDetail: false,
                CONFIRMATION_EXIT_PAGE_MODAL,
                CONFIRMATION_BOOK_APPOINTMENT_MODAL,
            }
        },
        mounted() {
            if (this.hasAddPermission) {
                this.getBulkData();
            } else {
                window.location.assign('/offline-clinic-booking');
            }
        },
        computed: {
            permissions() {
                return this.$store.getters.permissions;
            },
            hasAddPermission() {
                return this.permissions.includes('offline_clinic_booking:add');
            },
            hasEditPermission() {
                return this.permissions.includes('offline_clinic_booking:edit');
            },
            generateEditUrl() {
                return `/offline-clinic-booking/user/${this.$route.params.id}/edit?source=create-appointment`;
            },
            isFormCompleted() {
                if (
                    this.form.clinic
                    && !this.form.treatment.find((treatment) => !treatment.detail)
                    && this.form.date
                ) {
                    return true;
                }
                return false;
            },
            groupAvailableScheduleByLabel() {
                return this.options.availableSchedule.reduce((acc, curr) => {
                    const [hour, minute] = curr.time.split(':');
                    const totalMinutes = parseInt(hour) * 60 + parseInt(minute);

                    if (totalMinutes < 720) {
                        acc[0].list.push(curr);
                    } else if (totalMinutes < 1080) {
                        acc[1].list.push(curr);
                    } else {
                        acc[2].list.push(curr);
                    }

                    return acc;
                }, [{ label: 'Pagi', list: [] }, { label: 'Siang', list: [] }, { label: 'Malam', list: [] }]);
            },
            summaryData() {
                // sort treatment detail by priority
                const treatmentInformation = this.form.treatment
                    .filter((treatment) => treatment.detail)
                    .map((treatment) => ({
                        ...treatment,
                        detail: {
                            ...treatment.detail,
                            // fallback if there is no priority
                            priority: treatment.detail?.priority ?? -1,
                        },
                    }))
                    .sort((a, b) => {
                        // if priority is -1, forcing it to the end of the array
                        if (a.detail.priority === -1) return 1;
                        if (b.detail.priority === -1) return -1;
                        return a.detail.priority - b.detail.priority;
                    });
                let doctorName = '-';
                if (this.form.withConsultation) {
                    doctorName = this.form.doctor.adminName;
                    treatmentInformation.unshift({
                        group: { id: 0, name: 'Consultation' },
                        detail: {
                            id: 0,
                            title: '',
                            price: this.config.consultation.price || 0,
                            durationInMinutes: this.config.consultation.durationInMinutes || 0,
                        }
                    })
                }
                let treatmentTime = '-'
                if (this.form.time && treatmentInformation.length > 0) {
                    const incomingMinutes = treatmentInformation.reduce((acc, curr) => {
                        return acc + (curr.detail?.durationInMinutes || 0);
                    }, 0);

                    const endTime = this.calculateEndTime(this.form.time, incomingMinutes);

                    treatmentTime = `${this.form.time} - ${endTime}`;
                }
                return {
                    clinicName: this.form.clinic ? this.form.clinic.name : '-',
                    treatmentInformation,
                    doctorName,
                    estimatedTotalPrice: this.form.treatment.reduce((acc, curr) => {
                        return acc + (curr.detail?.price || 0);
                    }, 0),
                    treatmentDate: this.$moment(this.form.date).format('DD MMMM YYYY'),
                    treatmentTime,
                }
            },
        },
        methods: {
            getBulkData() {
                Promise.all([
                    this.axios.get(`/v2/clinical/offline-clinic-booking/users/${this.$route.params.id}`),
                    this.axios.get('/v2/misc/offline-clinics?isActive=true'),
                    this.axios.get('/v2/misc/clinic-treatment-groups'),
                    this.axios.get('/v2/misc/offline-clinic-teams'),
                    this.axios.get('/v2/clinical/offline-clinic-booking/config'),
                ])
                    .then(([user, clinics, treatmentGroup, doctor, config]) => {
                        this.user = user.data.data;
                        // withConsultation forced to be true if user.hasTreatmentHistory is false
                        if (!this.user.hasTreatmentHistory) this.form.withConsultation = true;
                        this.options.clinic = clinics.data.data.rows;
                        this.options.treatmentGroup = treatmentGroup.data.data.rows;
                        this.options.doctor = [...this.options.doctor, ...doctor.data.data.rows];
                        this.config = config.data.data;
                    })
                    .catch((err) => {
                        this.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.message,
                        });
                    });
            },
            toggleStep() {
                if (this.step === 'form') {
                    this.step = 'summary';
                    return;
                }
                this.step = 'form';
                this.$forceUpdate();
            },
            handleSelectTreatmentGroup(event, treatmentFormId) {
                // reset treatment detail in current treatmentFormId
                this.form.treatment[treatmentFormId].detail = null;
                this.options.availableSchedule = [];

                // check if treatmentGroup has treatmentDetail
                const treatmentDetail = this.options.treatmentGroup
                    .find((treatmentGroup) => treatmentGroup.id === event.id)?.treatmentDetail;

                if (treatmentDetail) {
                    this.$forceUpdate();
                    return;
                }

                // if not existed, fetch treatment detail data
                this.isLoadingTreatmentDetail = true;
                this.axios.get(`/v2/misc/clinic-treatment-groups/${event.id}`)
                    .then((res) => {
                        const treatmentGroupIndex = this.options.treatmentGroup
                            .findIndex((treatmentGroup) => treatmentGroup.id === event.id);
                        this.options.treatmentGroup[treatmentGroupIndex].treatmentDetail = res.data.data.clinicTreatmentDetails;
                        this.$forceUpdate();
                    })
                    .finally(() => {
                        this.isLoadingTreatmentDetail = false;
                    })
            },
            getTreatmentDetailOptions(selectedTreatmentGroup) {
                if (!selectedTreatmentGroup) {
                    return [];
                }

                return this.options.treatmentGroup
                    .find((treatmentGroup) => treatmentGroup.id === selectedTreatmentGroup.id)?.treatmentDetail ?? [];
            },
            addNewTreatment() {
                if (this.isLoading) return;
                this.form.treatment.push({
                    group: null,
                    detail: null,
                });
                this.options.availableSchedule = [];
                this.$forceUpdate();
            },
            removeTreatment(treatmentFormId) {
                if (this.isLoading) return;
                this.form.treatment.splice(treatmentFormId, 1);
                this.getAvailableSchedule();
                this.$forceUpdate();
            },
            generateDateOptions(selectedValue) {
                return selectedValue.setHours(0, 0, 0, 0) < new Date(this.$moment()).setHours(0, 0, 0, 0)
                    || selectedValue.setHours(0, 0, 0, 0) > new Date(this.$moment().add(1, 'months')).setHours(0, 0, 0, 0);
            },
            handleToggleAccordion(accordionId) {
                if (this.isLoading) return;
                if (this.openAccordion.includes(accordionId)) {
                    this.openAccordion = this.openAccordion.filter(id => id !== accordionId);
                    return;
                }
                this.openAccordion.push(accordionId);
            },
            generateScheduleTimeTooltip(currentValue) {
                if (!currentValue.isAvailable) return 'Unavailable';
                if (!currentValue.isOperationalHour) return 'Out of Operational Hours';
                return 'Available';
            },
            getAvailableSchedule() {
                this.form.time = null;
                // if form is not completed, do not get available schedule
                if (!this.isFormCompleted) return;

                this.isLoading = true;
                const params = {
                    date: this.$moment(this.form.date).format('YYYY-MM-DD'),
                    offlineClinicId: this.form.clinic.id,
                    withConsultation: this.form.withConsultation,
                    ...((this.form.withConsultation && this.form.doctor.id > 0) && { offlineClinicTeamId: this.form.doctor.id }),
                    clinicTreatmentDetailIds: this.form.treatment.map(treatment => treatment.detail.id),
                };
                this.axios.post('/v2/clinical/offline-clinic-booking/get-available-schedule', params)
                    .then((res) => {
                        this.options.availableSchedule = res.data.data;
                    })
                    .catch((err) => {
                        this.options.availableSchedule = [];
                        this.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.message,
                        });
                    })
                    .finally(() => {
                        this.isLoading = false;
                        // auto open accordion after get available schedule
                        if (!this.openAccordion.length) {
                            this.openAccordion.push('Pagi');
                        }
                        this.$forceUpdate();
                    });
            },
            setScheduleTime(newValue) {
                if (this.isLoading) return;
                if (newValue) {
                    this.form.time = newValue;
                    return;
                }
                this.form.time = null;
            },
            openConfirmationExitPageModal() {
                this.$modal.show(CONFIRMATION_EXIT_PAGE_MODAL);
            },
            closeConfirmationExitPageModal(isRedirect = false) {
                this.$modal.hide(CONFIRMATION_EXIT_PAGE_MODAL);
                if (isRedirect) {
                    setTimeout(() => {
                        this.$router.push('/offline-clinic-booking/user');
                    }, 250);
                }
            },
            openConfirmationBookAppointmentModal() {
                this.$modal.show(CONFIRMATION_BOOK_APPOINTMENT_MODAL);
            },
            closeConfirmationBookAppointmentModal(isBookAppointment = false) {
                if (!isBookAppointment) {
                    this.$modal.hide(CONFIRMATION_BOOK_APPOINTMENT_MODAL);
                    return;
                }

                this.onBookApointment();
            },
            async onSave() {
                if (this.isLoading) return;

                const isValid = await this.$refs.observer.validate();
                if (isValid) {
                    if (this.form.time) {
                        this.toggleStep();
                    } else {
						this.$notify({
							type: 'error',
							title: 'Error',
							text: 'Please select schedule time.',
						});
                    }
                }
            },
            calculateEndTime(startTime, incomingMinutes) {
                const [startHours, startMinutes] = startTime.split(':');
                const startTimeInMinutes = (parseInt(startHours) * 60) + parseInt(startMinutes);
                const totalMinutes = startTimeInMinutes + incomingMinutes;
    
                const hours = Math.floor(totalMinutes / 60) % 24;
                const minutes = totalMinutes % 60;

                const finalHours = hours < 10 ? `0${hours}` : hours;
                const finalMinutes = minutes < 10 ? `0${minutes}` : minutes;

                return `${finalHours}:${finalMinutes}`;
            },
            onBookApointment() {
                this.isLoading = true;
                this.closeConfirmationBookAppointmentModal();

                const params = {
                    date: this.$moment(this.form.date).format('YYYY-MM-DD'),
                    time: this.form.time,
                    offlineClinicId: this.form.clinic.id,
                    withConsultation: this.form.withConsultation,
                    ...((this.form.withConsultation && this.form.doctor.id > 0) && { offlineClinicTeamId: this.form.doctor.id }),
                    clinicTreatmentDetailIds: this.form.treatment.map(treatment => treatment.detail.id),
                    userId: parseInt(this.$route.params.id),
                };

                this.axios.post('/v2/clinical/offline-clinic-booking', { ...params })
                    .then((res) => {
                        this.$swal.fire({
                            title: res.data.message,
                            text: 'New appointment created',
                            icon: 'success',
                            confirmButtonColor: '#0036A0',
                            confirmButtonText: 'OK'
                        })
                        
                        setTimeout(() => {
                            this.$router.push('/offline-clinic-booking');
                        }, 250);
                    })
                    .catch((err) => {
                        this.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.message,
                        });
                        
                        // back to form page and refetch available schedule
                        this.toggleStep();
                        setTimeout(() => {
                            this.getAvailableSchedule();
                        }, 250);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    })
            }
        },
    }
</script>

<style scoped>
    .gap-1 {
        gap: 0.5rem;
    }
	.gap-2 {
		gap: 0.75rem;
	}
	.gap-3 {
		gap: 1rem;
	}
    .edit-user-btn {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff !important;
        border-radius: 4px;
    }
    .transition-all-ease {
        transition: all 0.3s ease;
    }
    .schedule-loader {
        font-size: 48px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .available-schedule-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-left: -4px;
        margin-right: -4px;
    }
    .available-schedule-none {
        width: 100%;
        padding: 8px;
        border: 1px solid rgb(242, 244, 246);
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgb(152, 152, 154);
    }
    .schedule-list {
        flex: 0 0 25%;
        padding: 4px;
    }
    .btn-schedule-list {
        background-color: #fff;
        width: 100%;
        padding: 8px;
        border: 1px solid rgb(190, 194, 210);
        border-radius: 6px;
    }
    .btn-schedule-list-disabled {
        background-color: rgb(242, 244, 246);
        border: 1px solid rgb(242, 244, 246);
        color: rgb(152, 152, 154);
        cursor: not-allowed;
    }
    .btn-schedule-list-offside {
        background-color: #fff6ee;
        border: 1px solid #FF9F43;
    }
    .btn-schedule-list-selected {
        background-color: #def0ff;
        border: 1px solid #1284e7;
    }
    .modal-container{
        overflow: auto;
        padding: 1rem;
        height: 100%;
    }
    @media screen and (max-width: 768px) {
        #treatment-information {
            overflow-x: auto;
        }
    }
</style>
  
<style>
    .error-input-multiselect .multiselect .multiselect__tags {
        border: 1.5px solid #dc2626 !important;
    }
    .error-input-multiselect .multiselect .multiselect__placeholder {
        color: #dc2626 !important;
    }
    .error-input-multiselect .multiselect .multiselect__select:before {
        border-color: #dc2626 transparent transparent !important;
    }
    #schedule-date .mx-calendar {
        padding: 16px;
        width: 300px;
        max-width: 100%;
    }
    #schedule-date .mx-datepicker-main {
        border-radius: 6px;
    }
    #schedule-date .mx-table-date td, #schedule-date .mx-table-date th {
        height: 36px;
        font-size: 15px;
    }
    #schedule-date .mx-calendar-header .mx-btn {
        font-size: 15px;
    }
    #schedule-date .mx-calendar-content {
        height: 260px;
    }
</style>